import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

export const routes = [{
  path: '/',
  name: 'home',
  component: () => import('@/pages/Home.vue')
},{
  path: '/products',
  name: 'products',
  component: () => import('@/pages/Product.vue')
},{
  path: '/about',
  name: 'about',
  component: () => import('@/pages/Company.vue')
},{
  path: '/contact',
  name: 'contact',
  component: () => import('@/pages/Support.vue')
}]

const router = new Router({
  // mode: 'history',
  base: process.env.BASE_URL || '/',
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth' }
    }

    return { x: 0, y: 0 }
  },
  routes
})

/**
 * Before each route update
 */
router.beforeEach((to, from, next) => {
  return next()
})

/**
 * After each route update
 */
router.afterEach((to, from) => {
})

export default router
